import Validator from './Validator'

export default class extends Validator {
  InvalidParameters () {
    return {
      Name: [
        {
          validator: value => !!value && value.match(/\w+ \w+/),
          message: value => 'Please provide your full name.'
        }
      ],
      Email: [
        {
          validator: value => !!value && value.match(/^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/),
          message: value => 'Please provide a valid email address.'
        }
      ],
      Message: [
        {
          validator: value => !!value && value.length > 10,
          message: value => 'Please provide a meaningful message.'
        }
      ],
      Mobile: [
        {
          validator: value => !!value && value.length >= 10,
          message: value => 'Please provide a valid number.'
        }
      ]
    }
  }
}
