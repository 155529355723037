<template>
  <page-layout>
    <template slot="side">
      <h1 class="title is-2 has-text-weight-black">
        <span class="has-text-secondary" v-t="`contact.greeting`"></span>
        <br>
        <span v-t="`contact.lets-talk`"></span>
      </h1>
      <hr>
      <p class="subtitle" v-t="`contact.hear-from-you`"></p>
    </template>

    <template slot="mobile">
      <h1 class="title is-2 has-text-weight-black">
        <span class="has-text-secondary" v-t="`contact.greeting`"></span>
        <br>
        <span v-t="`contact.lets-talk`"></span>
      </h1>
      <p class="subtitle" v-t="`contact.hear-from-you`"></p>
      <hr>
    </template>

    <template slot="main">
      <p-form :handler="submitForm" :validator="validator" :transform="transform">
        <template slot="fields" slot-scope="{ errors, data }">
          <p-field :errors="errors.Name">
            <p-label name="name" slot="label" class="has-text-grey is-size-7" v-t="`contact.form.name`"></p-label>
            <p-input name="name" :placeholder="$t('contact.form.placeholders.name')" v-model="data.Name"></p-input>
          </p-field>

          <p-field :errors="errors.Mobile">
            <p-label name="phone" slot="label" class="has-text-grey is-size-7" v-t="`contact.form.phone`"></p-label>
            <p-input name="phone" :placeholder="$t('contact.form.placeholders.mobile')" v-model="data.Mobile"></p-input>
          </p-field>

          <p-field :errors="errors.Email">
            <p-label name="email" slot="label" class="has-text-grey is-size-7" v-t="`contact.form.email`"></p-label>
            <p-input name="email" :placeholder="$t('contact.form.placeholders.email')" v-model="data.Email"></p-input>
          </p-field>

          <p-field :errors="errors.Message">
            <p-label name="message" slot="label" class="has-text-grey is-size-7" v-t="`contact.form.message`"></p-label>
            <p-textarea name="message" :placeholder="$t('contact.form.placeholders.message')" v-model="data.Message"></p-textarea>
          </p-field>
        </template>

        <template slot="actions" slot-scope="{ stage, stages }">
          <p-field>
            <p-button class="is-uppercase" bold primary :loading="stage === stages.SUBMITTED" v-if="stage !== stages.SUCCEEDED" v-t="`buttons.submit`">
            </p-button>
            <p-button bold secondary v-if="stage === stages.SUCCEEDED">
              <i class="fa fa-check"></i>
              <span v-t="`buttons.sent`"></span>
            </p-button>
            <span class="help is-success" v-if="stage === stages.SUCCEEDED" v-t="`contact.form.success`"></span>
          </p-field>
        </template>
      </p-form>
    </template>
  </page-layout>
</template>

<script>
import Utm from '@/core/services/Utm'
import ContactFormValidator from '@/core/validators/ContactFormValidator'
import config from '@/config'

export default {
  data () {
    return {
      validator: new ContactFormValidator(),
      zenDeskURL: config.zenDeskHelp
    }
  },
  mounted () {
    window.location.href = this.zenDeskURL
  },
  methods: {
    async submitForm (data) {
      return this.$config.apiEndpoints.contactUs.post({}, data)
        // Proxy a failed request.
        .catch(({ response }) => Promise.reject(response.data))
    },

    async transform (data) {
      return {
        FirstName: (data.Name || '').split(' ')[0],
        LastName: (data.Name || '').split(' ')[1] || '',
        Mobile: data.Mobile,
        Email: data.Email,
        Message: data.Message,
        Browser: this.$browser.name,
        UTM_Source: Utm.get().utm_source,
        UTM_Medium: Utm.get().utm_medium,
        UTM_Campaign: Utm.get().utm_campaign
      }
    }
  }
}
</script>
